import { ApolloProvider } from '@apollo/client';
import '@fontsource/bree-serif/400.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/700.css';
import { LayoutTree } from '@moxy/next-layout';
import { Elements } from '@stripe/react-stripe-js';
import { AppProps } from 'next/app';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { GlobalStyles } from 'twin.macro';

import hideSite from '@chartsy/shared/src/utils/hideSite';

import apolloClient from '../apolloClient';
import LayoutMain from '../components/Layout/Main';
import { ProvideAuth } from '../contexts/auth';
import { ProvideBasket } from '../contexts/basket';
import { ProvideCheckout } from '../contexts/checkout';
import initSentry from '../utils/initSentry';
import isSsr from '../utils/isSsr';
import stripePromise from '../utils/stripePromise';

initSentry();

export default function App({
  Component,
  pageProps,
  err,
}: AppProps & { err: unknown }) {
  return (
    <div tw="text-gray-800 font-sans antialiased">
      <ApolloProvider client={apolloClient}>
        <Elements stripe={stripePromise}>
          <ProvideAuth>
            <ProvideBasket>
              <ProvideCheckout>
                <GlobalStyles />
                <LayoutTree
                  Component={Component}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  pageProps={{ ...pageProps, err }}
                  defaultLayout={<LayoutMain />}
                />
                <Toaster />
              </ProvideCheckout>
            </ProvideBasket>
          </ProvideAuth>
        </Elements>
      </ApolloProvider>
    </div>
  );
}

if (!isSsr() && process.env.NEXT_PUBLIC_HIDE_SITE) {
  hideSite();
}
