import React, { ReactNode } from 'react';
import 'twin.macro';

import Footer from '../Footer';
import Header, { HeaderProps } from '../Header';
import { Main } from '../Styled';

const LayoutMain = ({
  children,
  headerProps,
}: {
  children?: ReactNode;
  headerProps?: HeaderProps;
}) => (
  <div tw="flex flex-col min-h-screen">
    <Header {...headerProps} />
    <Main>{children}</Main>
    <Footer />
  </div>
);

export default LayoutMain;
